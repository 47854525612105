import { AuthBoundary } from '@/features/auth';
import { DemoStart } from '@/features/chatbot';
import { ErrorBoundary, ErrorProvider } from '@/features/error-handling';
import { Role, UserSettings } from '@/features/user';
import { VaultHome } from '@/features/vaults';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { initializeApp } from 'firebase/app';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import emptyChartImg from '../src/assets/empty-chart.png';
import firebaseConfig from './bootstrap/firebase-config';
import sentryConfig from './bootstrap/sentry-config';
import { RouteGuard } from './components';
import Layout from './components/layout';
import Demo from './routes/demo';
import ExhibitBuilderPage from './routes/exhibit-builder';
import GenerateProject from './routes/generate-project';
import Home from './routes/home';
import {
  KnowledgeFinderDataEntryPage,
  KnowledgeFinderDataUploadPage,
  KnowledgeFinderSearch
} from './routes/knowledge-finder';
import LoginPage from './routes/login';
import Project from './routes/project';
import RequestAccessPage from './routes/request-access';
import { VaultItemPage } from './routes/vault';
import theme from './styles/theme';
import RedirectToOverview from './routes/redirect';

const myProjects = [
  {
    title: 'Project 1',
    image: emptyChartImg,
    url: '/project'
  },
  {
    title: 'Project 1',
    image: emptyChartImg,
    url: '/project'
  },
  {
    title: 'Project 1',
    image: emptyChartImg,
    url: '/project'
  }
];
const sharedProjects = [
  {
    title: 'Project 3',
    image: emptyChartImg,
    url: '/project'
  },
  {
    title: 'Project 4',
    image: emptyChartImg,
    url: '/project'
  },
  {
    title: 'Project 5',
    image: emptyChartImg,
    url: '/project'
  }
];

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      // #region default routing
      { path: '/', element: <RedirectToOverview /> },
      // #endregion default routing
      // #region project builder chat demo
      {
        path: '/demo',
        children: [
          {
            path: '',
            element: (
              <RouteGuard requiredRole={Role.DEMO}>
                <DemoStart />
              </RouteGuard>
            )
          },
          {
            path: 'p/:projectId/c/:chatId',
            element: (
              <RouteGuard requiredRole={Role.DEMO}>
                <Demo />
              </RouteGuard>
            )
          }
        ]
      },
      // #endregion project builder chat demo
      // #region chat w/ data
      {
        path: '/chat-with-data',
        children: [
          {
            path: '',
            element: (
              <RouteGuard requiredRole={Role.DEMO}>
                <KnowledgeFinderDataEntryPage />
              </RouteGuard>
            )
          },
          {
            path: 'p/:projectId',
            element: (
              <RouteGuard requiredRole={Role.DEMO}>
                <KnowledgeFinderDataUploadPage />
              </RouteGuard>
            )
          }
        ]
      },
      // #endregion chat w/ data
      // #region Knowledge Finder (route is currently redundant to preserve existing links)
      {
        path: '/knowledge-finder',
        children: [
          {
            path: '',
            element: (
              <RouteGuard requiredRole={Role.DEMO}>
                <KnowledgeFinderDataEntryPage />
              </RouteGuard>
            )
          },
          {
            path: 'p/:projectId',
            element: (
              <RouteGuard requiredRole={Role.DEMO}>
                <KnowledgeFinderDataUploadPage />
              </RouteGuard>
            )
          }
        ]
      },
      // #endregion chat w/ data
      // #region search
      {
        path: '/search/p/:projectId',
        element: <KnowledgeFinderSearch />
      },
      // #endregion search
      // #region vaults
      // #region DHT vault
      {
        path: '/vaults/:vaultName',
        element: <VaultHome />, //todo: add redirect to overview here
        children: [
          {
            children: [
              {
                path: ':tier/:page',
                element: <VaultItemPage />
              }
            ]
          }
        ]
      },
      // #endregion DHT vault
      // #endregion vaults
      // #region sanity check pathing organization
      {
        path: '/projects',
        element: <Home projects={myProjects} sharedProjects={sharedProjects} />
      },
      {
        path: '/only-shared-projects',
        element: <Home projects={[]} sharedProjects={sharedProjects} />
      },
      {
        path: '/only-owned-projects',
        element: <Home projects={myProjects} sharedProjects={[]} />
      },
      {
        path: '/no-projects',
        element: <Home projects={[]} sharedProjects={[]} />
      },
      {
        path: '/project',
        element: <Project />
      },
      {
        path: '/create-new',
        element: <GenerateProject />
      },
      {
        path: '/user-settings',
        element: <UserSettings />
      },
      {
        path: '/builder',
        element: <ExhibitBuilderPage />
      },
      {
        path: '/request-access',
        element: <RequestAccessPage />
      },
      {
        path: '/login',
        element: <LoginPage />
      }
      // #endregion sanity check pathing organization
    ]
  }
]);

// #region bootstrap
initializeApp(firebaseConfig);
Sentry.init(sentryConfig);
// #endregion bootstrap

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Auth0Provider
      domain="prism-mvp.us.auth0.com"
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID || 'nWKkLxfIvdHGvq2TEL05TIfwzy1KZzDB'}
      authorizationParams={{
        audience: import.meta.env.VITE_AUTH0_AUDIENCE || 'mvp-prism-backend',
        redirect_uri: window.location.href || window.location.origin,
        scope: 'openid profile email'
      }}
      // for demo to prevent logout on refresh
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <ErrorProvider>
        <ErrorBoundary>
          <AuthBoundary>
            <ThemeProvider theme={theme}>
              <RouterProvider router={router} />
            </ThemeProvider>
          </AuthBoundary>
        </ErrorBoundary>
      </ErrorProvider>
    </Auth0Provider>
  </React.StrictMode>
);
