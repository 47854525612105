import { Card, Box, Typography, PaletteColor } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type PrismCardProps = {
  color: PaletteColor;
  content: ReactNode;
  title: string;
  label?: string;
  height?: number;
  width?: number;
  titleFontSize?: number;
  tags?: Tag[];
};

// chip-style tag to show on cards
export type Tag = {
  // true types based on db
  uuid: string;
  name: string;

  // initial scaffolding types
  title?: string;
  type?: string;
  color?: string;
};

/**
 * Reusable Prism card component with color behind it
 * - allows for chip tags or a text label at the bottom of the card
 */
export const PrismCard = ({
  height = 235,
  width = 230,
  color,
  content,
  title,
  label,
  titleFontSize = 16
}: PrismCardProps): JSX.Element => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Card
        sx={{
          height: height + 5,
          width,
          borderRadius: '45px',
          dropShadow: ' 0px 0px 65px -42px #B1D1E5',
          position: 'absolute',
          backgroundColor: color.main,
          top: 0,
          bottom: 0
        }}
        elevation={0}
      ></Card>
      <Card
        elevation={0}
        sx={{
          height,
          width,
          backgroundColor: '#fff',
          borderRadius: '45px',
          dropShadow: ' 0px 0px 65px -42px #B1D1E5',
          position: 'absolute',
          top: 0,
          bottom: 0
        }}
      >
        <Box sx={{ position: 'relative', m: 3, height: '100%' }}>
          <Box>
            <Typography
              variant="h6"
              sx={{ fontFamily: 'Canela Text', fontSize: titleFontSize, color: color.dark, fontWeight: 300 }}
            >
              {title}
            </Typography>
          </Box>
          <Box sx={{ overflow: 'auto' }}>{content}</Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              pb: 8,
              backgroundColor: '#fff',
              maxHeight: 40
            }}
          >
            {label && (
              <Typography variant="caption" sx={{ color: color.dark, fontSize: 12, textTransform: 'uppercase' }}>
                {label}
              </Typography>
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

/**
 * Linked card component
 */
export const LinkedPrismCard = ({ link, ...args }: { link: string } & PrismCardProps): JSX.Element => {
  return (
    <Link to={link}>
      <PrismCard {...args} />
    </Link>
  );
};
