import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function RedirectToOverview() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/vaults/dht/Overview/Overview');
  }, [navigate]);

  return <Box></Box>;
}
